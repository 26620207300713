<template>
  <article class="container-xl" id="settings">
      <section class="row page-title">
          <div class="col-12 text-md-left">
              <h2>Параметры электропривода</h2>
          </div>
      </section>
      <section class="row">
          <div class="col-12">
              <div class="model-type">
                  <span>Тип</span>
                  <span class='text-capitalize'>{{ offer.turn }}</span>
                  <button
                      class="button button-action"
                      @click.prevent='confirmReturn'
                  >
                      <img :src='"../../assets/media/ic16_edit.svg"' class='mx-2'/>
                      <span>Изменить</span>
                  </button>
              </div>
          </div>
      </section>
      <section>
          <div class="row mb-4">
              <div class="col-12">
                  <h3>Основные параметры</h3>
              </div>
          </div>
          <fieldset>
              <div class="row align-items-center">
                  <div class="col-12">
                      <SelectElement
                          label='Область применения (исполнение)'
                          :loading='loadingArea'
                          :items='items.areas'
                          :enable-error='enableError'
                          v-model='area'
                      />
                  </div>
              </div>
              <div class="row align-items-center">
                  <div class="col-12 col-sm-4 col-lg-4">
                      <SelectElement
                          label='Диапазон крутящих моментов'
                          :loading='loadingLimits'
                          :items='items.limits'
                          :enable-error='enableError'
                          suffix='Н·м'
                          v-model='limit'
                      />
                  </div>
                  <div class="col-12 col-sm-4 col-lg-4">
                      <div class="link-block">
                          <small>Если вы не нашли нужный крутящий момент, то ознакомьтесь с решением в разделе</small>
                          <a
                              class="button button-action"
                              target='_blank'
                              :href='link'>
                              <img :src='"../../assets/media/ic16_edit.svg"' class='mx-2'/>
                              <span>«Комбинированный привод»</span>
                          </a>
                      </div>
                  </div>
              </div>
              <div class='row'>
                  <div class="col-12 col-sm-6 col-lg-4">
                      <SelectElement
                          :label='rearrangementLabel'
                          :suffix='rearrangementSuffix'
                          :disabled='disableRearrangement'
                          :loading='loadingRearrangement'
                          :items='items.rearrangement'
                          :enable-error='enableError'
                          v-model='rearrangement'
                      />
                  </div>
                  <div class="col-12 col-sm-6 col-lg-4">
                      <SelectElement
                          label='Режим работы по ГОСТ IEC 60034-1'
                          :disabled='disableMode'
                          :loading='loadingMode'
                          :items='items.modes'
                          :enable-error='enableError'
                          v-model='mode'
                      />
                  </div>
                  <div class="col-12 col-sm-6 col-lg-4">
                      <SelectElement
                          label='Рабочий ход'
                          :disabled='disabled'
                          :loading='loading'
                          :items='items.workingStrokes'
                          :enable-error='enableError'
                          v-model='workingStrokes'
                      />
                  </div>
              </div>
              <div class="row">
                  <div class="col-12 col-sm-6 col-lg-6">
                      <SelectElement
                          label='Климатическое исполнение по ГОСТ 15150'
                          :disabled='disabled'
                          :loading='loading'
                          :items='items.climatics'
                          :enable-error='enableError'
                          v-model='climatic'
                      />
                  </div>
                  <div class="col-12 col-sm-6 col-lg-6">
                      <SelectElement
                          label='Степень защиты по ГОСТ 14254-2015 (IEC 60529:2013)'
                          :disabled='disabled'
                          :loading='loading'
                          :items='items.protections'
                          :enable-error='enableError'
                          v-model='protection'
                      />
                  </div>
              </div>
          </fieldset>
      </section>
      <section>
          <div class="row mt-5 mb-4">
              <div class="col-12">
                  <h3>Параметры питания ЭЛЕКТРОПРИВОДА</h3>
              </div>
          </div>
          <fieldset>
              <div class="row">
                  <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                      <SelectElement
                          label='Параметры питания и частота'
                          :disabled='disabled'
                          :loading='loading'
                          :items='items.powers'
                          :enable-error='enableError'
                          v-model='power'
                      />
                  </div>
              </div>
          </fieldset>
      </section>
      <section
          v-if='items.electricalSchemas.length > 0'
          class="scheme-section"
      >
          <div class="row mt-5">
              <div class="col-12 col-md-5 col-lg-6">
                  <h3 class="mb-4">Основная электрическая схема</h3>
                  <div>
                      <h5 class="mb-3">Обозначение схемы электропитания</h5>
                      <div>
                          <RadioElement
                              v-for='item in items.electricalSchemas'
                              :key='item.id'
                              :title='item.title'
                              :sub-title='item.subTitle'
                              :value='item'
                              :checked='items.electricalSchemas.length === 1'
                              :error='enableError && offer.electricalSchema.id === null'
                              @select='selectElectricalSchema'
                              name='electrical_schema'
                          />
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-7 col-lg-6">
                  <div
                      v-if='electricalSchemaImage !== null'
                      class="device-img d-flex justify-content-center"
                  >
                      <img
                          :src='electricalSchemaImage'
                          @click='preview(electricalSchemaImage)'
                      />
                  </div>
              </div>
          </div>
      </section>
      <section
          v-if='items.additionalTools.length > 0'
          class="scheme-section"
      >
          <div class="row mt-5">
              <div class="col-12 col-md-5 col-lg-6">
                  <h3 class="mb-4">Дополнительное оснащение и электрическая схема</h3>
                  <div>
                      <h5 class="mb-3">Оснащение / обозначение схемы</h5>
                      <div>
                          <RadioElement
                              v-for='item in items.additionalTools'
                              :key='item.id'
                              :title='item.value'
                              :value='item'
                              :error='enableError && offer.additionalTool.id === null'
                              @select='selectAdditionalTool'
                              name='additional_ool'
                          />
                      </div>
                  </div>
              </div>
              <div class="col-12 col-md-7 col-lg-6">
                  <div
                      v-if='additionalToolImage !== null'
                      class="device-img d-flex justify-content-center"
                  >
                      <img
                          :src='additionalToolImage'
                          @click='preview(additionalToolImage)'
                      />
                  </div>
              </div>
          </div>
      </section>
      <section>
          <div class="row mt-5 mb-4">
              <div class="col-12">
                  <h3>Присоединение к арматуре</h3>
              </div>
          </div>
          <fieldset>
              <div class="row">
                  <div class="col-12 col-sm-6 col-lg-4">
                      <SelectElement
                          label='Размер фланца'
                          :disabled='disabled'
                          :loading='loading'
                          :items='items.flanges'
                          :enable-error='enableError'
                          v-model='flange'
                      />
                  </div>
                  <div class="col-12 col-sm-6 col-lg-5">
                      <SelectElement
                          label='Размер втулки'
                          :caption="offer.connection.description ? 'Размер отверстия втулки электропривода, соотвествующее валу или штоку арматуры' : null"
                          :disabled='disableConnections'
                          :loading='loadingConnections'
                          :items='items.connections'
                          :enable-error='enableError'
                          v-model='connection'
                      />
                  </div>
              </div>
          </fieldset>
      </section>
      <section class="row mt-5 page-nav">
          <div class="col-12">
              <nav>
                  <a
                      class="button button-sec button-lg"
                      href="#"
                      @click.prevent='confirmReturn'
                      :class="{'opacity-75': saving}"
                  >
                      Назад
                  </a>
                  <a
                      class="button button-brand button-lg"
                      href="#"
                      :class="{'opacity-75': saving}"
                      @click.prevent='save'
                  >
                      Посмотреть результат подбора
                      <div
                          class="spinner-border loader spinner-small mx-2 position-static"
                          role="status"
                          v-if='saving'
                      ></div>
                  </a>
              </nav>
          </div>
      </section>
  </article>
</template>

<script>
import SelectElement from '@/components/forms/SelectElement.vue';
import { mapGetters } from 'vuex';
import { CLEAR_OFFER, GET_OFFER, UPDATE_OFFER } from '@/store/modules/offer';
import APIService from '@/services/api.service';
import RadioElement from '@/components/forms/RadioElement.vue';
import ReturnModal from '@/components/modals/ConfirmModal.vue';
import { AxiosError } from 'axios';

export default {
    components: {RadioElement, SelectElement},
    computed: {
        ...mapGetters({
            offer: GET_OFFER,
        }),
        rearrangementSuffix() {
            return this.offer.rearrangementType === 1
                ? 'об/мин'
                : 'с/90°';
        },
        rearrangementLabel() {
            return this.offer.rearrangementType === 1
                ? 'Частота вращения'
                : 'Время перестановки';
        },
        electricalSchemaImage() {
            return this.offer.electricalSchema.image !== null
                ? process.env.MEDIA + this.offer.electricalSchema.image
                : null
        },
        additionalToolImage() {
            return this.offer.additionalTool.image !== null
                ? process.env.MEDIA + this.offer.additionalTool.image
                : null
        },
        rearrangement: {
            get() {
                return this.offer.rearrangement !== null
                    ? this.offer.rearrangement + (this.offer.rearrangementType === 1 ? ' об/мин' : ' с/90°')
                    : this.offer.rearrangement;
            },
            set(rearrangement) {
                if (this.rearrangement === null || this.loadingRearrangement || this.items.rearrangement.length <= 1) {
                    return this.$store.commit(UPDATE_OFFER, {
                        rearrangement
                    });
                }

                this.$modal.show(
                    ReturnModal,
                    {
                        yes: 'Да',
                        no: 'Нет',
                        title: 'Вы точно хотите изменить значение?',
                        text: 'Последующие выбранные данные в полях будут сброшены.'
                    },
                    {
                        width: 500,
                        height: 250,
                        classes: 'vm__modal'
                    },
                    {
                        'closed': () => {
                            this.clearItems();
                            this.$store.commit(CLEAR_OFFER, [
                                'turn', 'type', 'model', 'size_id', 'area', 'limit', 'limits', 'rearrangement'
                            ]);

                            this.$store.commit(UPDATE_OFFER, {
                                rearrangement
                            });
                        }
                    }
                );
            }
        },
        area: {
            get() {
                return this.offer.area
            },
            set(area) {
                if (this.area === null || this.items.areas.length <= 1) {
                    return this.$store.commit(UPDATE_OFFER, {
                        'area': area.value,
                        'size_id': area.id,
                        'rearrangementType': area.type
                    });
                }

                this.$modal.show(
                    ReturnModal,
                    {
                        yes: 'Да',
                        no: 'Нет',
                        title: 'Вы точно хотите изменить значение?',
                        text: 'Последующие выбранные данные в полях будут сброшены.'
                    },
                    {
                        width: 500,
                        height: 250,
                        classes: 'vm__modal'
                    },
                    {
                        'closed': () => {
                            this.clearItems(true);
                            this.$store.commit(CLEAR_OFFER, [
                                'turn', 'type', 'model', 'size_id', 'area', 'limit', 'limits'
                            ]);

                            this.$store.commit(UPDATE_OFFER, {
                                'area': area.value,
                                'size_id': area.id
                            });
                        }
                    }
                );
            }
        },
        limit: {
            get() {
                return this.offer.limit !== null
                    ? this.offer.limit + ' Н·м'
                    : this.offer.limit;
            },
            set(limit) {
                if (this.limit === null || this.items.limits.length <= 1) {
                    return this.$store.commit(UPDATE_OFFER, {
                        'limit': limit.value,
                        'limits': {
                            'min': limit.min,
                            'max': limit.max
                        }
                    });
                }

                this.$modal.show(
                    ReturnModal,
                    {
                        yes: 'Да',
                        no: 'Нет',
                        title: 'Вы точно хотите изменить значение?',
                        text: 'Последующие выбранные данные в полях будут сброшены.'
                    },
                    {
                        width: 500,
                        height: 250,
                        classes: 'vm__modal'
                    },
                    {
                        'closed': () => {
                            this.clearItems(true);
                            this.$store.commit(CLEAR_OFFER, [
                                'turn', 'type', 'model', 'size_id', 'area', 'limit', 'limits'
                            ]);

                            this.$store.commit(UPDATE_OFFER, {
                                'limit': limit.value,
                                'limits': {
                                    'min': limit.min,
                                    'max': limit.max
                                }
                            });
                        }
                    }
                );
            }
        },
        flange: {
            get() {
                return this.offer.flange
            },
            set(flange) {
                if (this.connection === null) {
                    return this.$store.commit(UPDATE_OFFER, {
                        flange
                    });
                }
            }
        },
        connection: {
            get() {
                return this.offer.connection.value
            },
            set(connection) {
                this.$store.commit(UPDATE_OFFER, {
                    connection
                });
            }
        },
        workingStrokes: {
            get() {
                return this.offer.workingStroke.value
            },
            set(workingStrokes) {
                this.$store.commit(UPDATE_OFFER, {
                    'workingStroke': workingStrokes
                });
            }
        },
        power: {
            get() {
                return this.offer.power.value
            },
            set(power) {
                this.$store.commit(UPDATE_OFFER, {
                    power,
                    'electricalSchema': {
                        'id': null,
                        'title': null,
                        'subTitle': null,
                        'image': null
                    },
                });
            }
        },
        protection: {
            get() {
                return this.offer.protection.value
            },
            set(protection) {
                this.$store.commit(UPDATE_OFFER, {
                    protection
                });
            }
        },
        mode: {
            get() {
                return this.offer.mode
            },
            set(mode) {
                if (this.mode === null || this.loadingMode || this.items.modes.length <= 1) {
                    return this.$store.commit(UPDATE_OFFER, {
                        mode
                    });
                }

                this.$modal.show(
                    ReturnModal,
                    {
                        yes: 'Да',
                        no: 'Нет',
                        title: 'Вы точно хотите изменить значение?',
                        text: 'Последующие выбранные данные в полях будут сброшены.'
                    },
                    {
                        width: 500,
                        height: 250,
                        classes: 'vm__modal'
                    },
                    {
                        'closed': () => {
                            this.clearItems();
                            this.$store.commit(CLEAR_OFFER, [
                                'turn', 'mode', 'type', 'model', 'size_id', 'area', 'limit', 'limits', 'rearrangement'
                            ]);

                            this.$store.commit(UPDATE_OFFER, {
                                mode
                            });
                        }
                    }
                );
            }
        },
        climatic: {
            get() {
                return this.offer.climatic.value
            },
            set(climatic) {
                this.$store.commit(UPDATE_OFFER, {
                    climatic
                });
            }
        }
    },
    watch: {
        area() {
            if (this.area !== null && this.limit !== null) {
                this.loadRearrangement();
            }
        },
        limit() {
            if (this.area !== null && this.limit !== null) {
                this.loadRearrangement();
            }
        },
        rearrangement() {
            if (this.rearrangement !== null) {
                this.loadModes();
            }
        },
        mode() {
            if (this.mode !== null) {
                this.loadScopedData();
            }
        },
        flange() {
            if (this.flange !== null) {
                this.loadArmatureAttachments();
            }
        },
        power() {
            if (this.power !== null) {
                this.loadElectricalSchemes();
            }
        }
    },
    data() {
        return {
            link: process.env.LINK,

            flangeExists: false,

            disableRearrangement: true,
            disableMode: true,
            disableConnections: true,
            disabled: true,

            loadingRearrangement: false,
            loadingMode: false,
            loadingArea: true,
            loadingLimits: true,
            loadingConnections: false,
            loading: false,

            saving: false,
            enableError: false,

            items: {
                areas: [],
                limits: [],
                rearrangement: [],
                workingStrokes: [],
                modes: [],
                climatics: [],
                protections: [],
                powers: [],
                electricalSchemas: [],
                additionalTools: [],
                flanges: [],
                connections: []
            }
        }
    },
    async mounted() {
        try {
            const {areas, limits} = await APIService.loadAreasAndLimits(this.offer.model.replace(' об/мин', ''));

            this.items.areas = areas;
            this.items.limits = limits;
        } catch (e) {
            this.$toast.open({
                message: 'Возникла ошибка получения базовых данных',
                type: 'error',
                position: 'top-right'
            });
        } finally {
            this.loadingArea = false;
            this.loadingLimits = false;
        }
    },
    methods: {
        async loadElectricalSchemes() {
            this.items.electricalSchemas = [];

            try {
                this.items.electricalSchemas = await APIService.getElectricalSchemes(this.offer.size_id, this.offer.power.id);
            } catch (e) {
                this.$toast.open({
                    message: 'Возникла ошибка загрузки схем электропитания',
                    type: 'error',
                    position: 'top-right'
                });
            }
        },
        async loadArmatureAttachments() {
            this.items.connections = [];
            this.loadingConnections = true;
            this.disableConnections = true;

            try {
                this.items.connections = await APIService.getArmatureAttachments(this.offer.size_id, this.flange);
            } catch (e) {
                this.$toast.open({
                    message: 'Возникла ошибка обработки присоединения к арматуре',
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.loadingConnections = false;
                this.disableConnections = false;
            }
        },
        async save() {
            if (this.saving) {
                return;
            }

            this.saving = true;
            this.enableError = true;

            try {
                const summary = await APIService.getSummaryData(
                    this.offer.mode,
                    this.offer.rearrangement,
                    this.offer.model,
                    this.offer.limits.min,
                    this.offer.limits.max,
                    this.offer.power.id,
                    this.offer.additionalTool.id,
                    this.offer.electricalSchema.id,
                    this.offer.connection.id,
                    this.offer.protection.id,
                    this.offer.climatic.id,
                    this.offer.workingStroke.id
                );

                this.$store.commit(UPDATE_OFFER, {
                    'designation': summary.designation,
                    'manufacturing': summary.manufacturing,
                    'files': summary.files,
                    'id': summary.id,
                    'image': summary.image !== null
                        ? process.env.DOWNLOAD_SITE + summary.image
                        : null
                });

                this.$emit('next');
            } catch (e) {
                let error = 'Возникла ошибка обработки результатов подбора';
                const element = document.querySelector('.error');

                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth'
                    });
                }

                if (e instanceof AxiosError) {
                    if (e.response.status === 422) {
                        error = 'Пожалуйста, заполните все поля конфигуратора';
                    }
                }

                this.$toast.open({
                    message: error,
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.saving = false;
            }
        },
        clearItems(rearrangement = false) {
            this.items.workingStrokes = [];
            this.items.modes = [];
            this.items.climatics = [];
            this.items.powers = [];
            this.items.protections = [];
            this.items.electricalSchemas = [];
            this.items.additionalTools = [];
            this.items.flanges = [];
            this.items.connections = [];

            if (rearrangement) {
                this.disableRearrangement = true;
                this.disabled = true;
                this.items.rearrangement = [];
            }
        },
        async loadModes() {
            this.loadingMode = true;

            try {
                this.disableMode = false;
                this.items.modes = await APIService.getModes(
                    this.offer.size_id,
                    this.offer.rearrangement,
                    this.offer.model,
                    this.offer.limits.min,
                    this.offer.limits.max
                );
            } catch (e) {
                this.$toast.open({
                    message: 'Возникла ошибка получения списка режима работы',
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.loadingMode = false;
            }
        },
        async loadScopedData() {
            this.loading = true;

            try {
                const {
                    workingStrokes,
                    climatics,
                    powers,
                    protections,
                    additionalTools,
                    flanges
                } = await APIService.getScopedData(
                    this.offer.size_id,
                    this.offer.rearrangement,
                    this.offer.model,
                    this.offer.mode,
                    this.offer.limits.min,
                    this.offer.limits.max
                );

                this.items.workingStrokes = workingStrokes;
                this.items.climatics = climatics;
                this.items.powers = powers;
                this.items.protections = protections;
                this.items.additionalTools = additionalTools;
                this.items.flanges = flanges;

                this.disabled = false;
            } catch (e) {
                this.$toast.open({
                    message: 'Возникла ошибка получения общих данных',
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.loading = false;
            }
        },
        async loadRearrangement() {
            this.loadingRearrangement = true;

            try {
                this.disableRearrangement = false;
                this.items.rearrangement = await APIService.loadRearrangement(
                    this.offer.size_id,
                    this.offer.model,
                    this.offer.limits.min,
                    this.offer.limits.max
                );
            } catch (e) {
                this.$toast.open({
                    message: 'Возникла ошибка получения времени перестановки',
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.loadingRearrangement = false;
            }
        },
        selectElectricalSchema(electricalSchema) {
            this.$store.commit(UPDATE_OFFER, {
                electricalSchema
            });
        },
        selectAdditionalTool(additionalTool) {
            this.$store.commit(UPDATE_OFFER, {
                additionalTool
            });
        },
        confirmReturn() {
            if (this.saving) {
                return;
            }

            this.$modal.show(
                ReturnModal,
                {
                    yes: 'Да, вернуться',
                    no: 'Остаться',
                    title: 'Вы точно хотите вернуться?',
                    text: 'Все ранее выбранные данные не будут сохранены.'
                },
                {
                    width: 500,
                    height: 250,
                    classes: 'vm__modal'
                },
                {
                    'closed': () => {
                        this.$emit('previous');
                        this.$store.commit(CLEAR_OFFER, [
                            'turn', 'type'
                        ]);
                    }
                }
            )
        },
        preview(image) {
            this.$imagePreview({
                initIndex: 0,
                images: [image],
                isEnableImagePageIndicator: false,
                isEnableDownloadImage: false,
                isEnableLoopToggle: false
            });
        }
    }
}
</script>
