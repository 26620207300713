<template>
    <div
            class="input-gr"
            :class="{'error': error && !loading}"
    >
        <textarea
                :placeholder='placeholder'
                :disabled='loading || disabled'
                :class="{'loading': loading || disabled}"
                v-model='prop'
        >
        </textarea>
        <span
                v-if='caption !== null'
                class="input-gr__caption"
        >
            {{ caption }}
        </span>
        <small
                v-if='error && !loading'
                class="input-gr__caption"
        >
            {{ help }}
        </small>
    </div>
</template>
<script>
export default {
    props: {
        required: {
            type: Boolean,
            default: false
        },
        enableError: {
            type: Boolean,
            default: true
        },
        caption: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        help: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        error() {
            if (!this.required) {
                return false;
            }

            if (!this.enableError) {
                return false;
            }

            return this.value === null;
        },
        prop: {
            get() {
                return this.value;
            },
            set(value) {
                return this.$emit('input', value);
            }
        }
    }
}
</script>
