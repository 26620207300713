import { TYPE_BALL_VALVE, TYPE_DISC_SHUTTER, TYPE_SHUT_OFF_VALVE, TYPE_WEDGE_GATE_VALVE } from '@/types/type.types';

export default class ImageUtils {
    static getStaticModelImageByType(type) {
        switch (type) {
            case TYPE_WEDGE_GATE_VALVE:
                return 'wedge_gate_valve.png'
            case TYPE_SHUT_OFF_VALVE:
                return 'shut_off_valve.png'
            case TYPE_BALL_VALVE:
                return 'ball_valve.png'
            case TYPE_DISC_SHUTTER:
                return 'disc_shutter.png'

        }

        return null;
    }
}
