export const API_PERFORMANCE_MODELS = process.env.API + '/v1/performances/getDesignationByTypeSize';
export const API_PERFORMANCE_AREAS_AND_LIMITS = process.env.API + '/v1/performances/getAreaAndLimitsByDesignation';

export const API_PERFORMANCE_REARRANGEMENT = process.env.API + '/v1/performances/getRearrangement';
export const API_PERFORMANCE_MODES = process.env.API + '/v1/performances/getModes';

export const API_PERFORMANCE_SCOPED_DATA = process.env.API + '/v1/configurator/getScopedData';
export const API_PERFORMANCE_SUMMARY_DATA = process.env.API + '/v1/configurator/getSummaryData';

export const API_DOWNLOAD = process.env.API + '/v1/configurator/getPdf/';

export const API_ARMATURE_ATTACHMENTS = process.env.API + '/v1/armature_attachments/getByFlangeSize';

export const API_ELECTRICAL_SCHEMES = process.env.API + '/v1/electrical_schemes/getBySizeAndPower';

export const API_SETTINGS = process.env.API + '/v1/settings/getSettings';

export const API_FILES_DOWNLOAD = process.env.DOWNLOAD_SITE + 'files/download/';

export const API_APPEAL = process.env.API + '/v1/appeal';

export const API_POLICY_COOKIES = process.env.API + '/v1/policy/cookies';
export const API_POLICY_PRIVATE = process.env.API + '/v1/policy/private';