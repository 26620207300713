<template>
    <label class="radio">
        <input
            type="radio"
            :name='name'
            :checked='selected'
            v-model='prop'
            ref='radio'
            @click='select'
        >
        <div class="radio__wrap">
            <div
                class="radio__view"
                :class="{'error': error}"
            ></div>
            <p v-html='title'></p>
        </div>
        <span
            v-if='subTitle !== null'
            v-html='subTitle'
        />
    </label>
</template>
<script>
export default {
    props: {
        error: {
            type: Boolean,
            default: false
        },
        value: {
            required: true
        },
        checked: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        }
    },
    computed: {
        prop: {
            get() {
                return this.value;
            },
            set(value) {
                return this.$emit('input', value);
            }
        }
    },
    mounted() {
        if (this.checked) {
            this.$refs.radio.click();
        }
    },
    methods: {
        select() {
            this.$emit('select', this.value);
        }
    }
}
</script>