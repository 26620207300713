<template>
    <div
            class="input-gr"
            :class="{'error': error && !loading}"
    >
        <label>{{ label }} <span style='color: red' v-if='required'>*</span></label>
        <input
            :type='type'
            :placeholder='placeholder'
            :disabled='loading || disabled'
            :class="{'loading': loading || disabled}"
            v-mask="mask"
            v-model='prop'
        >
        <span
            v-if='caption !== null'
            class="input-gr__caption"
        >
            {{ caption }}
        </span>
        <small
            v-if='error && !loading'
            class="input-gr__caption"
        >
            {{ help }}
        </small>
    </div>
</template>
<script>
export default {
    props: {
        mask: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'text'
        },
        required: {
            type: Boolean,
            default: true
        },
        enableError: {
            type: Boolean,
            default: true
        },
        caption: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        help: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        force: {
          type: Boolean,
          default: false
        }
    },
    computed: {
        error() {
            if (this.force) {
                return true;
            }

            if (!this.required) {
                return false;
            }

            if (!this.enableError) {
                return false;
            }

            // хардкод под номер телефона
            if (this.mask !== null) {
                return this.value === null || this.value.length !== 18;
            }

            if (this.type === 'email') {
               return this.value === null || !/\S+@\S+\.\S+/.test(this.value);
            }

            if (this.limitMax !== null) {
              return this.value > this.limitMax;
            }

            return this.value === null;
        },
        prop: {
            get() {
                return this.value;
            },
            set(value) {
                return this.$emit('input', value);
            }
        }
    }
}
</script>
