<template>
  <div class='p-4 d-flex flex-column justify-content-between h-100'>
    <div>
      <h2>Вы можете добавить комментарий</h2>
      <div class='mt-4'>
        <fieldset ref='fields'>
          <div class="row align-items-center">
            <div class="col-12">
              <TextAreaElement
                  placeholder='Оставьте короткий текстовый комментарий, который будет добавлен в PDF файл с результатом подбора. Например: наименование проекта, количество электроприводов, № опросного листа или № позиции.'
                  v-model='comment'
              />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class='justify-content-end d-flex gap-3 mt-3'>
      <button
          class='button button-sec button-lg'
          @click='() => download(false)'
      >
        Пропустить
      </button>
      <button
          class='button button-brand button-lg'
          @click="download"
      >
        Добавить
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_OFFER } from '@/store/modules/offer';
import TextAreaElement from '@/components/forms/TextAreaElement.vue';
import { API_DOWNLOAD } from '@/types/api.types';

export default {
  components: { TextAreaElement },
  data() {
    return {
      comment: null
    }
  },
  computed: {
    ...mapGetters({
      offer: GET_OFFER,
    })
  },
  methods: {
    async download(comment = true) {
      if (comment && this.comment !== null && this.comment.length > 0) {
        window.open(API_DOWNLOAD + this.offer.id + '?' + new URLSearchParams({
          comment: this.comment
        }).toString());
      } else {
        window.open(API_DOWNLOAD + this.offer.id);
      }
      this.$emit('close');
    }
  }
}
</script>
