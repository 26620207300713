<template>
  <article class="container-xl" id="offer">
      <section class="row page-title">
          <div class="col-12 text-md-left">
              <h2>Предложение</h2>
          </div>
      </section>
      <section class="row offer-about">
          <div class="col-12 col-md-7 order-1 order-md-0">
              <table>
                  <tbody>
                  <tr>
                    <td>Тип арматуры</td>
                    <td>{{ getType() }}</td>
                  </tr>
                  <tr>
                    <td>Модель электропривода</td>
                    <td>{{ model }}</td>
                  </tr>
                  <tr>
                    <td>Исполнение</td>
                    <td>{{ designation }}</td>
                  </tr>
                  <tr>
                    <td>Технические условия</td>
                    <td>{{ offer.manufacturing }}</td>
                  </tr>
                  </tbody>
              </table>
              <p v-html='getDescription()'></p>
              <button
                  class="button button-action"
                  @click.prevent='copy'
              >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.5 1H4.5C4.36739 1 4.24021 1.05268 4.14645 1.14645C4.05268 1.24021 4 1.36739 4 1.5V4H1.5C1.36739 4 1.24021 4.05268 1.14645 4.14645C1.05268 4.24021 1 4.36739 1 4.5V14.5C1 14.6326 1.05268 14.7598 1.14645 14.8536C1.24021 14.9473 1.36739 15 1.5 15H11.5C11.6326 15 11.7598 14.9473 11.8536 14.8536C11.9473 14.7598 12 14.6326 12 14.5V12H14.5C14.6326 12 14.7598 11.9473 14.8536 11.8536C14.9473 11.7598 15 11.6326 15 11.5V1.5C15 1.36739 14.9473 1.24021 14.8536 1.14645C14.7598 1.05268 14.6326 1 14.5 1ZM11 14H2V5H11V14ZM14 11H12V4.5C12 4.36739 11.9473 4.24021 11.8536 4.14645C11.7598 4.05268 11.6326 4 11.5 4H5V2H14V11Z" fill="#6E7491"></path>
                  </svg>
                  <span>Копировать описание изделия</span>
              </button>
          </div>
          <div class="col-12 col-md-5">
              <div
                v-if='offer.image !== null'
                class="device-img d-flex justify-content-center"
              >
                  <img
                    :src='offer.image'
                    @click='preview(offer.image)'
                  />
              </div>
          </div>
      </section>
      <div class="row offer-actions">
          <div class="col-12 d-flex d-sm-block flex-column">
              <button
                  @click.prevent='show'
                  class="button button-brand button-lg"
              >
                  Заказать
              </button>
              <button
                  @click.prevent='download()'
                  class="button button-sec button-lg"
              >
                  Сохранить результат подбора
              </button>
              <button
                  class="button button-sec button-lg"
                  @click.prevent='reset()'
              >
                  Повторить подбор
              </button>
          </div>
      </div>
      <hr
          v-if='offer.files.length !== null'
          class='mt-lg-5 mb-lg-5'
      >
      <section
          v-if='offer.files !== null'
          class="row page-title"
      >
          <div class="col-12 text-md-left">
              <h2>Документация для подобранного электропривода</h2>
          </div>
      </section>
      <OfferStepFiles
          v-if='offer.files !== null'
          :files='offer.files'
      />
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { CLEAR_OFFER, GET_OFFER } from '@/store/modules/offer';
import ReturnModal from '@/components/modals/ConfirmModal.vue';
import OfferStepFiles from '@/components/steps/OfferStepFiles.vue';
import TextUtils from '@/utils/text.utils';
import OrderModal from '@/components/modals/OrderModal.vue';
import CommentModal from '@/components/modals/CommentModal.vue';

export default {
    components: {OfferStepFiles},
    data() {
        return {
            'files': []
        }
    },
    computed: {
        ...mapGetters({
            offer: GET_OFFER,
        }),
        model() {
          return this.offer.model.replace('об/мин', '').replace('с/90°', '')
        },
        designation() {
            return this.offer.designation.join(' ');
        }
    },
    mounted() {
        this.$nextTick(() => window.scrollTo({
            top: 0,
            behavior: 'smooth'
        }));
    },
    methods: {
        async download() {
          this.$modal.show(
              CommentModal,
              null,
              {
                width: 600,
                height: 355,
                classes: 'vm__modal'
              }
          );
        },
        copy() {
            let text = 'Тип арматуры: ' + TextUtils.toUpperCaseFirstLetter(this.offer.type || this.offer.turn) + "\n";
            text += 'Модель электропривода: ' + this.offer.model + "\n";
            text += 'Исполнение: ' + this.designation + "\n\n";
            text += this.getDescription();
            // Из под API могут и другие символы приходить, в таком случае регулярку нужно расширить
            text = text.replace(/<[^>]*>/g, '');
            text = text.replace(/&deg;/g, '°');
            text = text.replace(/&#8960;/g, '⌀');

            navigator.clipboard.writeText(text).then(() => this.$toast.open({
                message: 'Описание успешно скопировано!',
                position: 'top-right'
            }));
        },
        getDescription() {
            let description = this.offer.area;

            if (this.offer.limits.min == 0 && this.offer.limits.max == 0) {
                description += ', номинальный (максимальный) крутящий момент -';
            } else if (this.offer.limits.min === this.offer.limits.max) {
                description += ', номинальный (максимальный) крутящий момент ';
                description += this.offer.limits.min;
            } else if (this.offer.limits.min == 0) {
                description += ', номинальный (максимальный) крутящий момент ';
                description += 'до ' + this.offer.limits.max;
            } else if (this.offer.limits.max == 0) {
                description += ', номинальный (минимальный) крутящий момент ';
                description += 'от ' + this.offer.limits.min;
            } else {
                description += ', диапазон крутящих моментов ';
                description += this.offer.limits.min + ' - ' + this.offer.limits.max;
            }

            description += ' Нм, ';

            if (this.offer.rearrangementType === 1) {
                description += 'частота вращения ' + this.offer.rearrangement + ' об/мин, ';
            } else {
                description += 'время перестановки ' + this.offer.rearrangement + ' с/90°, ';
            }

            description += 'режим работы ' + this.offer.mode + ' по ГОСТ183-74, ';
            description += 'параметры электропитания ' + this.offer.power.value;
            description += ', рабочий ход ' + this.offer.workingStroke.value;
            description += ', климатическое исполнение ' + this.offer.climatic.value;
            description += ', степень защиты ' + this.offer.protection.value + ' по ГОСТ 14254-2015';
            description += ', присоединение к арматуре: ' + this.offer.flange + ', ' + this.offer.connection.value;
            description += ', схема подключения ' + this.offer.electricalSchema.title;

            if (this.offer.electricalSchema.subTitle !== null) {
                description += ' ' + this.offer.electricalSchema.subTitle;
            }

            description += ', дополнительное оснащение: ' + TextUtils.toLowerCaseFirstLetter(this.offer.additionalTool.value) + '.';

            return description;
        },
        getType() {
            return TextUtils.toUpperCaseFirstLetter(this.offer.type || this.offer.turn);
        },
        reset() {
            this.$modal.show(
                ReturnModal,
                {
                    yes: 'Да',
                    no: 'Нет',
                    title: 'Вы точно хотите изменить значение?',
                    text: 'Последующие выбранные данные в полях будут сброшены.'
                },
                {
                    width: 500,
                    height: 250,
                    classes: 'vm__modal'
                },
                {
                    'closed': () => {
                        this.$store.commit(CLEAR_OFFER);
                        this.$emit('previous', 1);
                    }
                }
            );
        },
        preview(image) {
            this.$imagePreview({
                initIndex: 0,
                images: [image],
                isEnableImagePageIndicator: false,
                isEnableDownloadImage: false,
                isEnableLoopToggle: false
            });
        },

        show() {
            this.$modal.show(
                OrderModal,
                {
                    order: true
                },
                {
                    width: 600,
                    height: 'auto',
                    classes: 'vm__modal'
                }
            );
        }
    }
}
</script>
