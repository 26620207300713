<template>
    <div class='p-4 d-flex flex-column justify-content-between h-100'>
        <div>
            <h2>
                {{ title }}
            </h2>
            <div class='mt-3'>
                {{ text }}
            </div>
        </div>
        <div class='justify-content-end d-flex gap-3'>
            <button
                    class='button button-sec button-lg'
                    @click='$modal.hideAll'
            >
                {{ no }}
            </button>
            <button
                class='button button-brand button-lg'
                @click="$emit('close')"
            >
                {{ yes }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        yes: {
            type: String,
            required: true
        },
        no: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>
