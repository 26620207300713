import Vue from 'vue';
import App from './App.vue';
import ToastPlugin from 'vue-toast-notification';
import VueMeta from 'vue-meta';
import VModal from 'vue-js-modal';
import imagePreview from 'image-preview-vue';
import VueMask from 'v-mask';
import store from './store/store';
import PolicyCookiePage from '@/PolicyCookiePage.vue';
import 'image-preview-vue/lib/imagepreviewvue.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import './assets/scss/app.scss';
import PolicyPrivatePage from '@/PolicyPrivatePage.vue';
import VueGtm from '@gtm-support/vue2-gtm';

Vue.config.productionTip = process.env.DEBUG
Vue.use(ToastPlugin);
Vue.use(VueGtm, {
  id: 'GTM-K4HVTH2N', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});
Vue.use(VueMask);
Vue.use(imagePreview);
Vue.use(VModal);
Vue.use(VueMeta);

const routes = {
  '/': App,
  '/cookies': PolicyCookiePage,
  '/private': PolicyPrivatePage
}

new Vue({
  store,
  render: (e) => e(routes[window.location.pathname] || App)
}).$mount('#app');
