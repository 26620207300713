<template>
    <div id="app">
        <HeaderElement/>
        <main>
            <article class="container-xl static">
                <ul class='breadcrumbs'>
                    <li><a href="/">Конфигуратор</a> » Правила обработки персональных данных</li>
                </ul>
                <div
                        class="spinner-border loader"
                        role="status"
                        v-if='loading'
                ></div>
                <section
                        class="row"
                        :class='{"loading": loading}'
                        v-html='text'
                >
                </section>
            </article>
        </main>
        <FooterElement/>
    </div>
</template>

<script>
import HeaderElement from '@/components/layout/HeaderElement.vue';
import FooterElement from '@/components/layout/FooterElement.vue';
import APIService from '@/services/api.service';

export default {
    metaInfo: {
        title: 'Конфигуратор электроприводов и трубопроводной арматуры',
        meta: [
            {
                name: 'description',
                content: 'Сервис по подбору электропривода. Управления электроприводом. Калькулятор для подбора решения по управлению трубопроводной арматурой.'
            }
        ]
    },
    components: {
        FooterElement,
        HeaderElement
    },
    data() {
        return {
            loading: true,
            text: null
        }
    },
    async mounted() {
        let link = document.querySelector("link[rel*='icon']");
        link.href = require('./assets/media/favicon.jpg');

        try {
            this.text = await APIService.getPrivatePolicy();
            this.loading = false;
        } catch (e) {
            this.$toast.open({
                message: 'Возникла ошибка загрузки страницы',
                type: 'error',
                position: 'top-right'
            });
        }
    }
}
</script>
