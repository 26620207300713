<template>
    <footer>
        <div class='cookie' v-if='cookies'>
            <div class="container-xl d-flex justify-content-between align-items-center">
                <div style='width: 85%'>
                    Продолжая просмотр сайта я соглашаюсь с использованием файлов cookie владельцем сайта в соответствии с <a href='/cookies' target='_blank'>«Политикой в отношении файлов cookie»</a>, в том числе на передачу данных, указанных в Политике, третьим лицам — статистическим службам сети Интернет.
                </div>
                <div>
                    <button
                        class='button button-sec button-lg'
                        @click.prevent='hideCookies'
                    >
                        Принять
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center footer">
            <div class="container-xl">
                <div class="row">
                    <div class="col-6 col-sm-4 d-flex align-items-sm-center align-items-start">
                        <a class="button button-action" :href="site">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 7.5H13.5M3.5 7.5L7.5 3.5M3.5 7.5L7.5 11.5" stroke="currentColor" stroke-linecap="square" stroke-linejoin="bevel"></path>
                            </svg>
                            <span>Вернуться на сайт</span>
                        </a>
                    </div>
                    <div class="col-12 col-sm-4 order-1 order-sm-0">
                        <div class="footer__copyright text-center order-1 order-sm-0 d-flex justify-content-center d-sm-block">
                            <small>&copy; {{ new Date().getFullYear() }} Все права защищены.&nbsp;</small>
                            <small>Предложение не является офертой.</small>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4">
                        <div
                            v-if='settings.phone !== null'
                            class="footer__contacts text-right"
                        >
                            <small>Связаться с отделом продаж</small>
                            <a class="button button-action link" :href="'tel:+' + call">{{ settings.phone }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import APIService from '@/services/api.service';
import { mapGetters } from 'vuex';
import { GET_SETTINGS, UPDATE_SETTINGS } from '@/store/modules/settings';

export default {
    data() {
        return {
            site: process.env.SITE,
            phone: null,
            cookies: false
        }
    },
    computed: {
        ...mapGetters({
          settings: GET_SETTINGS,
        }),
        call() {
            if (this.settings.phone === null) {
                return null;
            }

            return this.settings.phone.match(/\d+/g).map(x => parseInt(x)).join('');
        }
    },
    async mounted() {
      try {
        this.$store.commit(UPDATE_SETTINGS, await APIService.getSettings());
      } catch (e) {
        this.$toast.open({
          message: 'Возникла ошибка загрузки страницы',
          type: 'error',
          position: 'top-right'
        });
      }

      this.cookies = localStorage.getItem('cookies') === null;
    },
    methods: {
        hideCookies() {
            localStorage.setItem('cookies', '1');
            this.cookies = false;
        }
    }
}
</script>
