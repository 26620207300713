<template>
    <section class="offer-spec">
        <div
            v-for='(category, i) in existCategories'
            :key='"category_" + i'
            class="offer-spec__block"
        >
            <div class="row">
                <div class="col-12 offer-spec__subtitle">
                    <h4 class="d-flex align-items-center">
                        <img
                            v-if='getImage(category) !== null'
                            :src='getImage(category)'
                        >
                        <span>{{ category }}</span>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div
                    v-for='(file, i) in files[category]'
                    :key='"file_" + i'
                    class="col-6"
                >
                    <a
                        :href='getLink(file["hash"])'
                        class="file-link"
                        target='_blank'
                    >
                        <span>{{ file['display_name'] }}</span>
                        <span>*.{{ file['extension'] }}, {{ ((file['size'] / 1000) / 1000).toFixed(2) }} MB </span>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { API_FILES_DOWNLOAD } from '@/types/api.types';

export default {
    props: {
        files: {
            required: true
        }
    },
    data() {
        return {
            categories: [
                'Общая информация',
                'Техническая информация',
                'Эксплуатационная документация',
                'Декларации и сертификаты',
                'Конструкторская документация'
            ]
        }
    },
    computed: {
        existCategories() {
            const categories = [];

            this.categories.map((category) => {
                // eslint-disable-next-line no-prototype-builtins
               if (this.files.hasOwnProperty(category)) {
                   categories.push(category);
               }
            });

            return categories;
        }
    },
    methods: {
        getLink(hash) {
            return API_FILES_DOWNLOAD + hash;
        },
        getImage(category) {
            switch (category) {
                case 'Общая информация':
                    return require('../../assets/media/file_category_general.svg');
                case 'Техническая информация':
                    return require('../../assets/media/file_category_tech.svg');
                case 'Эксплутационная документация':
                    return require('../../assets/media/file_category_using.svg');
                case 'Декларации и сертификаты':
                    return require('../../assets/media/file_category_certs.svg');
                case 'Конструкторская документация':
                    return require('../../assets/media/file_category_construct.svg');
            }

            return null;
        }
    }
}
</script>
