export default class ObjectUtils {
    static setNull(object, except = []) {
        if (typeof object !== 'object' || object === null) {
            return null;
        }

        for (let field in object) {
            if (except.includes(field)) {
                continue;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (object.hasOwnProperty(field)) {
                object[field] = ObjectUtils.setNull(object[field]);
            }
        }

        return object;
    }
}
