import ObjectUtils from '@/utils/object.utils';

const OFFER = 'offer/';
const GET = '_get';
const UPDATE = '_updated';
const CLEAR = '_clear';

export const GET_OFFER = OFFER + GET
export const UPDATE_OFFER = OFFER + UPDATE
export const CLEAR_OFFER = OFFER + CLEAR

export default {
    namespaced: true,
    state: () => {
        // return {
        //     'designation': [],
        //     'files': [],
        //     'id': 1,
        //     'image': null,
        //
        //     'turn': 'неполнооборотный',
        //     'type': 'шаровый кран',
        //     'model': 'ГЗ-ОФ-110/11(М)',
        //     'size_id': 2,
        //     'area': 'Электропривод неполнооборотный общепромышленного исполнения',
        //     'limit': '55 - 110',
        //     'limits': {
        //         max: '110',
        //         min: '0'
        //     },
        //     'workingStroke': {
        //         'id': 6,
        //         'value': 'до 270&deg;'
        //     },
        //     'climatic': {
        //         'id': 11,
        //         'value': 'Т1, ТМ1 от минус 10 до плюс 70 &deg;С'
        //     },
        //     'power': {
        //         'id': 4,
        //         'value': 'АС  230В'
        //     },
        //     'protection': {
        //         'id': 2,
        //         'value': 'IP 67'
        //     },
        //     'electricalSchema': {
        //         'id': 11,
        //         'image': 'storage/CDOptc31Ja6hcizCNk6rthqKbQSqfOI5US56g2DS.png',
        //         'subTitle': 'AC 230В 50 Гц',
        //         'title': 'CE 413-11A'
        //     },
        //     'additionalTool': {
        //         'id': 10,
        //         'image': 'storage/QJTwTUapZ8sKIv5Uzp4R4lq3fdDJxcsPWu5HOSbI.png',
        //         'value': 'Токовый датчик положения  ПТ-3 (пассивный) / 810CPT3'
        //     },
        //     'rearrangement': '11',
        //     'rearrangementType': 0,
        //     'mode': 'S2 - 15 мин',
        //     'flange': 'F07 или F10',
        //     'connection': {
        //         'description': false,
        //         'id': 47,
        //         'value': 'под вал с двумя лысками S H11 11'
        //     }
        // }
        return {
            'designation': [],
            'files': [],
            'image': null,
            'id': null,

            'turn': null,
            'type': null,
            'model': null,
            // test
            // 'turn': 'неполнооборотный',
            // 'type': 'шаровый кран',
            // 'model': 'ГЗ-ОФ-110/11(М)',
            'size_id': null,
            'area': null,
            'limit': null,
            'limits': {
                'min': 0,
                'max': 0
            },
            'workingStroke': {
                'id': null,
                'value': null
            },
            'climatic': {
                'id': null,
                'value': null
            },
            'power': {
                'id': null,
                'value': null
            },
            'protection': {
                'id': null,
                'value': null
            },
            'electricalSchema': {
                'id': null,
                'title': null,
                'subTitle': null,
                'image': null
            },
            'additionalTool': {
                'id': null,
                'value': null,
                'image': null
            },
            'rearrangement': null,
            'manufacturing': null,
            'rearrangementType': 0,
            'mode': null,
            'flange': null,
            'connection': {
                'id': null,
                'value': null,
                'description': false
            }
        }
    },
    getters: {
        [GET]: (state) => state,
    },
    mutations: {
        [UPDATE]: (state, payload) => Object.assign(state, payload),
        [CLEAR]: (state, payload) => Object.assign(state, ObjectUtils.setNull(state, payload))
    }
}
