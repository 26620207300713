<template>
  <div id="app">
      <HeaderElement/>
      <main>
          <StepperElement :step='step'/>
          <component
                  :is='stepComponent'
                  @next='() => this.step++'
                  @previous='(payload) => previous(payload)'
          />
      </main>
      <FooterElement/>
  </div>
</template>

<script>
import TypeStep from '@/components/steps/TypeStep.vue';
import HeaderElement from '@/components/layout/HeaderElement.vue';
import StepperElement from '@/components/StepperElement.vue';
import FooterElement from '@/components/layout/FooterElement.vue';
import ModelStep from '@/components/steps/ModelStep.vue';
import SettingsStep from '@/components/steps/SettingsStep.vue';
import OfferStep from '@/components/steps/OfferStep.vue';

export default {
    metaInfo: {
        title: 'Конфигуратор электроприводов и трубопроводной арматуры',
        meta: [
            {
                name: 'description',
                content: 'Сервис по подбору электропривода. Управления электроприводом. Калькулятор для подбора решения по управлению трубопроводной арматурой.'
            }
        ]
    },
    components: {
        FooterElement,
        StepperElement,
        HeaderElement
    },
    data() {
      return {
          step: 1
      }
    },
    computed: {
        stepComponent() {
            switch (this.step) {
                case 4:
                    return OfferStep;
                case 3:
                    return SettingsStep;
                case 2:
                    return ModelStep;
                default:
                    return TypeStep;
            }
        }
    },
    mounted() {
        let link = document.querySelector("link[rel*='icon']");
        link.href = require('./assets/media/favicon.jpg');
    },
    methods: {
        previous(step = null) {
            this.step = step ?? this.step - 1;
        }
    }
}
</script>
