<template>
    <section class="stepper-container">
        <div class="container-xl">
            <div class="row">
                <div class="stepper col-12 d-flex flex-wrap">
                    <div
                        class="stepper__block align-items-md-center"
                        :class="{'current': step === 1}"
                    >
                        <span>1</span>
                        <span>
                            Тип управляемой арматуры&nbsp;
                            <strong
                                :style="{display: (offer.type === null && offer.turn === null) ? 'none' : 'block'}"
                            >
                                {{ getDisplayType(offer.type || offer.turn) }}
                            </strong>
                        </span>
                    </div>
                    <div
                        class="stepper__block align-items-md-center"
                        :class="{'current': step === 2}"
                    >
                        <span>2</span>
                        <span>
                            Тип и модель электропривода&nbsp;
                            <strong
                                class='text-uppercase'
                                :style="{display: offer.model === null ? 'none' : 'block'}"
                            >
                                {{ offer.model }}
                            </strong>
                        </span>
                    </div>
                    <div
                        class="stepper__block align-items-md-center"
                        :class="{'current': step === 3}"
                    >
                        <span>3</span>
                        <span>Параметры электропривода</span>
                    </div>
                    <div
                        class="stepper__block align-items-md-center"
                        :class="{'current': step === 4}"
                    >
                        <span>4</span>
                        <span>Предложение</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_OFFER } from '@/store/modules/offer';
import TextUtils from '@/utils/text.utils';

export default {
    props: {
        step: {
            type: Number,
            default: 1
        }
    },
    computed: {
        ...mapGetters({
            offer: GET_OFFER,
        })
    },
    methods: {
        getDisplayType(type = null) {
            if (type === null) {
                return type;
            }

            return TextUtils.toUpperCaseFirstLetter(type);
        }
    }
}
</script>
