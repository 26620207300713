<template>
    <article class="container" id="type">
        <section class="row page-title">
            <div class="col-12 text-md-left">
                <h2>Тип управляемой арматуры</h2>
            </div>
        </section>
        <section class="row d-flex flex-wrap">
            <div class="col-12 col-lg-6 type-name text-md-left">
                <h3>Многооборотный</h3>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-lg-2">
                <a class="type-card" href="#" @click="setType(types[0], 'многооборотный')">
                    <img
                        v-if='getImage(types[0]) !== null'
                        :src='getImage(types[0])'
                    >
                    <svg v-else width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 82H16C11.6 82 8 78.4 8 74V22C8 17.6 11.6 14 16 14H80C84.4 14 88 17.6 88 22V74C88 78.4 84.4 82 80 82Z" fill="#F2F2F2"></path>
                        <path d="M70 38C73.3137 38 76 35.3137 76 32C76 28.6863 73.3137 26 70 26C66.6863 26 64 28.6863 64 32C64 35.3137 66.6863 38 70 38Z" fill="white"></path>
                        <path d="M40 32L18 64H62L40 32Z" fill="#BDBDBD"></path>
                        <path d="M62 44L46 64H78L62 44Z" fill="#E0E0E0"></path>
                    </svg>
                    <div class="button button-brand button-lg text-uppercase">{{ types[0] }}</div>
                </a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-lg-2">
                <a class="type-card" href="#" @click="setType(types[1], 'многооборотный')">
                    <img
                        v-if='getImage(types[1]) !== null'
                        :src='getImage(types[1])'
                    >
                    <svg v-else width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 82H16C11.6 82 8 78.4 8 74V22C8 17.6 11.6 14 16 14H80C84.4 14 88 17.6 88 22V74C88 78.4 84.4 82 80 82Z" fill="#F2F2F2"></path>
                        <path d="M70 38C73.3137 38 76 35.3137 76 32C76 28.6863 73.3137 26 70 26C66.6863 26 64 28.6863 64 32C64 35.3137 66.6863 38 70 38Z" fill="white"></path>
                        <path d="M40 32L18 64H62L40 32Z" fill="#BDBDBD"></path>
                        <path d="M62 44L46 64H78L62 44Z" fill="#E0E0E0"></path>
                    </svg>
                    <div class="button button-brand button-lg text-uppercase">{{ types[1] }}</div>
                </a>
            </div>
            <div class="col-12 col-lg-6 order-lg-1 text-md-left type-name">
                <h3>Неполнооборотный</h3>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-lg-2">
                <a class="type-card" href="#" @click="setType(types[2], 'неполнооборотный')">
                    <img
                        v-if='getImage(types[2]) !== null'
                        :src='getImage(types[2])'
                    >
                    <svg v-else width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 82H16C11.6 82 8 78.4 8 74V22C8 17.6 11.6 14 16 14H80C84.4 14 88 17.6 88 22V74C88 78.4 84.4 82 80 82Z" fill="#F2F2F2"></path>
                        <path d="M70 38C73.3137 38 76 35.3137 76 32C76 28.6863 73.3137 26 70 26C66.6863 26 64 28.6863 64 32C64 35.3137 66.6863 38 70 38Z" fill="white"></path>
                        <path d="M40 32L18 64H62L40 32Z" fill="#BDBDBD"></path>
                        <path d="M62 44L46 64H78L62 44Z" fill="#E0E0E0"></path>
                    </svg>
                    <div class="button button-brand button-lg text-uppercase">{{ types[2] }}</div>
                </a>
            </div>
            <div class="col-12 col-md-6 col-lg-3 order-lg-2">
                <a class="type-card" href="#" @click="setType(types[3], 'неполнооборотный')">
                    <img
                        v-if='getImage(types[3]) !== null'
                        :src='getImage(types[3])'
                    >
                    <svg v-else width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 82H16C11.6 82 8 78.4 8 74V22C8 17.6 11.6 14 16 14H80C84.4 14 88 17.6 88 22V74C88 78.4 84.4 82 80 82Z" fill="#F2F2F2"></path>
                        <path d="M70 38C73.3137 38 76 35.3137 76 32C76 28.6863 73.3137 26 70 26C66.6863 26 64 28.6863 64 32C64 35.3137 66.6863 38 70 38Z" fill="white"></path>
                        <path d="M40 32L18 64H62L40 32Z" fill="#BDBDBD"></path>
                        <path d="M62 44L46 64H78L62 44Z" fill="#E0E0E0"></path>
                    </svg>
                    <div class="button button-brand button-lg text-uppercase">{{ types[3] }}</div>
                </a>
            </div>
        </section>
        <section class="row page-nav">
            <div class="col-12">
                <nav>
                    <a
                        class="button button-sec button-lg"
                        href="#"
                        @click='next()'
                    >
                        Пропустить шаг
                    </a>
                </nav>
            </div>
        </section>
    </article>
</template>

<script>
import { TYPE_BALL_VALVE, TYPE_DISC_SHUTTER, TYPE_SHUT_OFF_VALVE, TYPE_WEDGE_GATE_VALVE } from '@/types/type.types';
import { mapGetters } from 'vuex';
import { CLEAR_OFFER, GET_OFFER, UPDATE_OFFER } from '@/store/modules/offer';
import ImageUtils from '@/utils/image.utils';

export default {
    computed: {
        ...mapGetters({
            offer: GET_OFFER,
        })
    },
    data() {
        return {
            types: [
                TYPE_WEDGE_GATE_VALVE,
                TYPE_SHUT_OFF_VALVE,
                TYPE_BALL_VALVE,
                TYPE_DISC_SHUTTER
            ]
        }
    },
    methods: {
        getImage(type) {
            const image = ImageUtils.getStaticModelImageByType(type);

            if (image === null) {
                return null;
            }

            return require('../../assets/media/' + image);
        },
        setType(type, turn) {
            this.next();
            this.$store.commit(UPDATE_OFFER, {
                type,
                turn
            });
        },
        next() {
            this.$store.commit(CLEAR_OFFER);
            this.$emit('next');
        }
    }
}
</script>
