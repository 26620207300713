<template>
    <header>
        <div class="container-xl">
            <div class="row">
                <div class="col-12 d-flex justify-content-md-between align-items-center flex-column flex-md-row">
                    <h1
                        class="text-center"
                        @click='redirect'
                    >
                        Конфигуратор электропривода
                    </h1>
                    <button
                        class="button button-sec button-lg"
                        @click='show'
                    >
                        Обратиться за консультацией
                    </button>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import OrderModal from '@/components/modals/OrderModal.vue';

export default {
    methods: {
        redirect() {
            window.location.href = '/';
        },
        show() {
            this.$modal.show(
                OrderModal,
                null,
                {
                    width: 600,
                    height: 'auto',
                    classes: 'vm__modal'
                }
            );
        }
    }
}
</script>
