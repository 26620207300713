import Vue from 'vue';
import Vuex from 'vuex';
import offer from './modules/offer';
import settings from './modules/settings';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        offer,
        settings
    },
    strict: process.env.DEBUG
});
