<template>
    <div class='p-4 d-flex flex-column justify-content-between h-100'>
        <div>
            <h2>
                {{ title }}
            </h2>
            <div class='mt-4'>
                <fieldset ref='fields'>
                    <div class="row align-items-center">
                        <div class="col-12 mb-3">
                            <InputElement
                                label='Имя'
                                placeholder='Ваше имя'
                                :loading='loading'
                                :enable-error='enableError'
                                v-model='name'
                            />
                        </div>
                        <div class="col-12 mb-3">
                            <InputElement
                                label='Наименование компании'
                                placeholder='Ваша компания'
                                :required='false'
                                :loading='loading'
                                :enable-error='enableError'
                                v-model='company'
                            />
                        </div>
                        <div class="col-6 mb-3">
                            <InputElement
                                label='E-mail'
                                type='email'
                                placeholder='Ваша электронная почта'
                                :loading='loading'
                                :enable-error='enableError'
                                v-model='email'
                            />
                        </div>
                        <div class="col-6 mb-3">
                            <InputElement
                                label='Телефон'
                                mask='+7 (###) ###-##-##'
                                :loading='loading'
                                :enable-error='enableError'
                                placeholder='+7 (___) ___-__-__'
                                v-model='phone'
                            />
                        </div>
                        <div class="col-12">
                            <TextAreaElement
                                :loading='loading'
                                :enable-error='enableError'
                                :placeholder='order ? "Укажите количество, срок поставки и другие пожелания по заказу" : ""'
                                v-model='comment'
                            />
                        </div>
                        <div class="col-12 mt-4">
                            <CheckboxElement
                                label='Даю согласие на обработку <a href="/private" target="_blank">персональных данных</a>'
                                v-model='agreement'
                                :loading='loading'
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class='justify-content-end d-flex gap-3 mt-3'>
            <button
                    class='button button-sec button-lg'
                    @click='$modal.hideAll'
                    :class="{'opacity-75': loading}"
            >
                Отменить
            </button>
            <button
                    class='button button-brand button-lg'
                    @click="submit"
            >
                Отправить
                <div
                    class="spinner-border loader spinner-small mx-2 position-static"
                    role="status"
                    :class="{'opacity-75': loading}"
                    v-if='loading'
                ></div>
            </button>
        </div>
    </div>
</template>

<script>
import InputElement from '@/components/forms/InputElement.vue';
import CheckboxElement from '@/components/forms/CheckboxElement.vue';
import APIService from '@/services/api.service';
import { AxiosError } from 'axios';
import { mapGetters } from 'vuex';
import { GET_OFFER } from '@/store/modules/offer';
import TextAreaElement from '@/components/forms/TextAreaElement.vue';

export default {
    components: {TextAreaElement, CheckboxElement, InputElement},
    props: {
        order: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            name: null,
            company: null,
            email: null,
            phone: null,
            agreement: false,
            comment: null,

            loading: false,
            enableError: false
        }
    },
    computed: {
        ...mapGetters({
            offer: GET_OFFER,
        }),
        title() {
            return this.order ? 'Заявка на заказ' : 'Обращение за консультацией';
        }
    },
    methods: {
        async submit() {
            if (this.loading) {
                return;
            }

            if (!this.agreement) {
                return this.$toast.open({
                    message: 'Отправка запроса без согласия на обработку персональных данные невозможна',
                    type: 'error',
                    position: 'top-right'
                });
            }

            if (this.$refs.fields.getElementsByClassName('error').length > 0) {
                return;
            }

            this.loading = true;
            this.enableError = true;

            try {
                await APIService.saveAppeal(
                    this.name,
                    this.email,
                    this.phone,
                    this.company,
                    this.comment,
                    this.order ? this.offer.id : null
                );

                this.$emit('close');
                this.$toast.open({
                    message: 'Запрос успешно отправлен!',
                    position: 'top-right'
                });
            } catch (e) {
                if (e instanceof AxiosError) {
                    if (e.response.status === 422) {
                        return;
                    }
                }

                this.$toast.open({
                    message: 'Возникла ошибка отправки запроса',
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
