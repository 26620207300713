<template>
    <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            id="checkbox"
            v-model='prop'
            :disabled='loading || disabled'
        >
        <label
            class="form-check-label"
            for="checkbox"
            v-html='label'
        ></label>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        prop: {
            get() {
                return this.value;
            },
            set(value) {
                return this.$emit('input', value);
            }
        }
    }
}
</script>
