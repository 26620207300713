<template>
  <article class="container-xl" id="model">
      <section class="row page-title">
          <div class="col-12 text-md-left">
              <h2>Тип и модель электропривода</h2>
          </div>
      </section>
      <section class="row">
          <div class="col-12 col-sm-7 col-lg-6">
              <div class="col-12">
                  <div class='model-type'>
                      <div class='radio__group'>
                          <RadioElement
                              :checked='offer.turn === "многооборотный"'
                              value='многооборотный'
                              title='Многооборотный'
                              name='electrical_schema'
                              :error='offer.turn == null'
                              @select='selectTurn'
                          />
                          <RadioElement
                              :checked='offer.turn === "неполнооборотный"'
                              title='Неполнооборотный'
                              value='неполнооборотный'
                              name='electrical_schema'
                              :error='offer.turn == null'
                              @select='selectTurn'
                          />
                      </div>
                      <small
                          class="input-gr__caption"
                          style='color: red'
                          v-if='offer.turn == null'
                      >
                          Вы не выбрали тип
                      </small>
                  </div>
              </div>
              <div class="col-12 mb-4">
                  <SelectElement
                      label='Область применения'
                      :disabled='loading || (offer.turn === null)'
                      :loading='loading'
                      :items='areas'
                      v-model='area'
                      :required='false'
                  />
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-6 mb-4">
                  <SelectElement
                      label='Выберите требуемый момент электропривода, Нм'
                      :disabled='loading || (offer.turn === null)'
                      :loading='loading'
                      :items='torques'
                      v-model='moment'
                      :required='false'
                  />
                </div>
                <div class="col-12 col-sm-6 col-lg-6 mb-4">
                  <SelectElement
                      v-if='offer.turn === "неполнооборотный"'
                      label='Время перестановки'
                      :disabled='loading || (offer.turn === null)'
                      :loading='loading'
                      :items='rearrangements'
                      v-model='rearrangement'
                      :required='false'
                  />
                  <SelectElement
                      v-if='offer.turn === "многооборотный"'
                      label='Выберите тип присоединения'
                      :disabled='loading || (offer.turn === null)'
                      :loading='loading'
                      :items='armatures'
                      v-model='connection'
                      :required='false'
                  />
                </div>
              </div>
              <div class="col-12">
                  <div class="model-select">
                      <SelectElement
                          :help="offer.turn !== null && offer.model == null ? 'Вы не выбрали модель' : null"
                          :items='models'
                          :loading='loading'
                          :disabled='offer.turn == null'
                          :enable-error='offer.turn !== null && offer.model == null'
                          label='Модель'
                          v-model='model'
                      />
                  </div>
              </div>
          </div>
          <div
              class="col-12 col-sm-5 offset-lg-1"
              :class="{'opacity-0': image === null}"
          >
              <div class="device-img d-flex justify-content-center">
                  <img
                      v-if='image !== null'
                      :src='image'
                  >
              </div>
          </div>
      </section>
      <section class="row mt-5 page-nav">
          <div class="col-12">
              <nav>
                  <a
                      class="button button-sec button-lg"
                      href="#"
                      @click='previous()'
                  >
                      Назад
                  </a>
                  <a
                      class="button button-brand button-lg"
                      href="#"
                      @click='next()'
                  >
                      Продолжить
                  </a>
              </nav>
          </div>
      </section>
  </article>
</template>

<script>
import SelectElement from '@/components/forms/SelectElement.vue';
import { mapGetters } from 'vuex';
import { CLEAR_OFFER, GET_OFFER, UPDATE_OFFER } from '@/store/modules/offer';
import APIService from '@/services/api.service';
import ImageUtils from '@/utils/image.utils';
import RadioElement from '@/components/forms/RadioElement.vue';
import { GET_SETTINGS } from '@/store/modules/settings';

export default {
    components: { RadioElement, SelectElement },
    data() {
        return {
            loading: false,
            error: false,
            models: [],
            areas: [],
            torques: [],
            rearrangements: [],
            armatures: [],
            armature: null,
            rearrangement: null,
            torque: null,
            image: null,
            area: null
        }
    },
    computed: {
        ...mapGetters({
          offer: GET_OFFER,
          settings: GET_SETTINGS,
        }),
        model: {
            get() {
                return this.offer.model
            },
            set(model) {
                this.$store.commit(UPDATE_OFFER, {
                    'model': model.value
                });
            }
        },
        moment: {
            get() {
                return this.torque
            },
            set(torque) {
                this.torque = torque === null ? null : torque.value;
            }
        },
        connection: {
            get() {
                return this.armature !== null ? this.armature.value : null
            },
            set(armature) {
                if (this.armature !== null && armature !== null && this.armature.id === armature.id) {
                    return;
                }

                this.armature = armature;
            }
        }
    },
    watch: {
        armature() {
          this.$store.commit(UPDATE_OFFER, {
            'model': null
          });

          this.load(false);
          console.log('armature');
        },
        rearrangement() {
            this.$store.commit(UPDATE_OFFER, {
                'model': null
            });

            this.load(false);
          console.log('rearrangement');
        },
        torque() {
            this.$store.commit(UPDATE_OFFER, {
               'model': null
            });

            this.rearrangement = null;
            this.armature = null;
            this.load(false);
          console.log('torque');
        },
        model: function(_new) {
            if (_new !== null) {
                this.error = false;
            }
        },
        area() {
          this.$store.commit(UPDATE_OFFER, {
            'model': null
          });

          this.torque = null;
          this.rearrangement = null;
          this.armature = null;
          console.log('area');
          this.load(false);
        }
    },
    mounted() {
        if (this.offer.turn !== null) {
            this.load();
        }

        if (this.offer.type === null) {
            return;
        }

        const image = ImageUtils.getStaticModelImageByType(this.offer.type);

        if (image === null) {
            return;
        }

        this.image = require('../../assets/media/' + image);
    },
    methods: {
        async load(clearArea = true) {
            this.loading = true;

            if (clearArea) {
                this.area = null;
            }

            try {
                const {
                    models,
                    areas,
                    torques,
                    rearrangements,
                    armatures
                } = await APIService.loadModelsAreas(
                    this.offer.turn,
                    this.offer.type,
                    this.area,
                    this.torque,
                    this.rearrangement,
                    this.armature !== null ? this.armature : null
                );

                if (this.offer.turn === 'многооборотный') {
                  models.map((item) => item.value = item.value + ' об/мин')
                }

                this.models = models;
                this.torques = torques;
                this.rearrangements = rearrangements;
                this.armatures = armatures;

                if (clearArea) {
                    this.areas = areas;
                }
            } catch (e) {
                this.$toast.open({
                    message: 'Возникла ошибка получения списка моделей',
                    type: 'error',
                    position: 'top-right'
                });
            } finally {
                this.loading = false;
            }
        },
        selectTurn(value) {
            if (value === this.offer.turn) {
                return;
            }

            this.rearrangement = null;
            this.armature = null;
            this.image = null;
            this.$store.commit(CLEAR_OFFER);
            this.$store.commit(UPDATE_OFFER, {
                'turn': value,
                'model': null
            });

            this.torque = null;
            this.load();
        },
        previous() {
            this.$store.commit(CLEAR_OFFER);
            this.$emit('previous');
        },
        next() {
            if (this.model === null) {
                this.error = true;
                return;
            }

            if (this.armature !== null) {
                this.$store.commit(UPDATE_OFFER, {
                    'flange': this.armature['flange'],
                    'connection': {
                        'description': false,
                        'id': this.armature['id'],
                        'value': this.armature['title']
                    }
                });
            }

            this.$emit('next');
        }
    }
}
</script>