const SETTINGS = 'settings/';
const GET = '_get';
const UPDATE = '_updated';

export const GET_SETTINGS = SETTINGS + GET
export const UPDATE_SETTINGS = SETTINGS + UPDATE

export default {
    namespaced: true,
    state: () => {
        return {
            'phone': null
        }
    },
    getters: {
        [GET]: (state) => state,
    },
    mutations: {
        [UPDATE]: (state, payload) => Object.assign(state, payload)
    }
}
