<template>
    <div
        class="input-gr"
        :class="{'error': error && !loading}"
    >
        <label>{{ label }}</label>
        <div
            class="select"
            :class="{'focused': active}"
            v-on-clickaway='close'
        >
            <div
                class="select__field"
                :class="{'loading': loading || disabled}"
                @click='() => items.length > 0 ? active = !active : active = false'
            >
                <div
                    class="spinner-border loader"
                    role="status"
                    v-if='loading'
                ></div>
                <span
                    v-if='value !== null'
                    class="select__plug"
                    v-html='value'
                ></span>
                <span
                    v-else
                    class="select__plug opacity-50"
                    v-html='placeholder'
                ></span>
            </div>
            <ul class="select__dropdown">
                <li
                    class="select__option unselect"
                    @click='select(null)'
                    v-if='!required && items.length > 1'
                >
                  Сбросить
                </li>
                <li
                    class="select__option"
                    v-for='(item, i) in items'
                    :key='i'
                    @click='select(item)'
                    v-html='(item.value || item) + " " + suffix'
                ></li>
            </ul>
            <span
                v-if='caption !== null'
                class="input-gr__caption"
            >
                {{ caption }}
            </span>
        </div>
        <small
            v-if='error && !loading'
            class="input-gr__caption"
        >
            {{ help }}
        </small>
    </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [ clickaway ],
    props: {
        required: {
            type: Boolean,
            default: true
        },
        enableError: {
            type: Boolean,
            default: true
        },
        caption: {
            type: String,
            default: null
        },
        suffix: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: null
        },
        value: {
            default: null
        },
        placeholder: {
            type: String,
            default: 'Выберите'
        },
        help: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            active: false
        }
    },
    mounted() {
        if (this.items.length === 1) {
            this.select(this.items[0]);
        }
    },
    computed: {
        error() {
            return this.required && this.enableError && this.value === null;
        }
    },
    watch: {
        items() {
            if (this.items.length === 1) {
                this.select(this.items[0]);
            }
        }
    },
    methods: {
        close() {
            if (this.active) {
                this.active = false;
            }
        },
        select(item) {
            this.$emit('input', item);
            this.close();
        }
    }
}
</script>
